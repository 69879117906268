import {
  amber, common, green, grey, red, indigo,
} from '@mui/material/colors';

const validatedColor = (color) => (/^#([0-9A-Fa-f]{3}){1,2}$/.test(color) ? color : null);

export default (server) => {
  const colors = {
    white: common.white,
    background: grey[50],
    primary: validatedColor(server?.attributes?.colorPrimary) || indigo[900],
    secondary: validatedColor(server?.attributes?.colorSecondary) || green[800],
    bannerColor: validatedColor(server?.attributes?.bannerColor),
    positive: green[500],
    medium: amber[700],
    negative: red[500],
    neutral: 'grey',
    geometry: '#3bb2d0',
    mapLine: indigo[500],
    mapPointCircle: '#0099FF',
    mapPointCircleStroke: '#f5f5f5',
    blue: '#0033FF',
    green: '#66C011',
    orange: '#FF9933',
    iot: '#565d37',
    icon: common.white,
  };

  return {
    background: {
      default: colors.background,
    },
    primary: {
      main: colors.primary,
    },
    secondary: {
      main: colors.secondary,
      contrastText: colors.white,
    },

    colors,
  };
};
